<template>
  <div>
    <h3>Locations</h3>

    <v-row>
      <v-col
      sm="6"
      md="4"
      v-for="product in $store.state.products.slice(1,5)"
      :key="product.name"
      >
      <VerticalProduct :product="product" :addToCart="addToCart" />
        <v-card
          sm="6"
      md="4"
  >
    <v-card-text>
      <h2 class="title mb-2">Hours</h2>

      <v-chip-group
        column
        multiple
      >
        <v-chip v-for="hour in product.hours" :key="hour" outlined> {{hour }}</v-chip>
        <!-- <v-chip v-for="service in product.services" :key="service" outlined> {{service }}</v-chip> -->

      </v-chip-group>
    </v-card-text>
  </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import VerticalProduct from '../cards/VerticalProduct'

export default {
  components: {
    VerticalProduct
  },
  methods: {
    addToCart (index, quantity = 1) {
      this.$store.commit('addItemToCart', { itemId: index, quantity })
      this.$store.commit('updateSnackbar', { show: true })
    }
  }
}
</script>
