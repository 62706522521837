<template>
  <v-sheet :height="sheetHeight">
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col>
<h1 class="text-center display-3"><img src="@/assets/images/logo-1200px-w.png" style="width:40%"></h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col >
                <p class="text-center" >
          Naz's Pharmacy is a group of pharmacies that have served the needs of our communities in the Lower Mainland for more than 20 years. We have grown from a modest operation to an organization that employs over 50 people in several locations. Our goal is to provide quality pharmacy services to all our patients in the Metro Vancouver area. If you're interested in the COVID-19 vaccine, please <a href="https://www.getvaccinated.gov.bc.ca/s/">book an appointment</a> at one of our locations using the COVID Immunization website.
        </p>
</v-col>
        <!--  Our success can be attributed to our continued dedication to prioritizing and meeting the needs of our community.
          Our goal has always been to go the extra mile for our patients, to deliver care that protects their health and safety, and to complement the treatment they receive from their doctor and others in their healthcare team. We believe everyone deserves access to care, especially marginalized groups as they are the most medically vulnerable. We make every effort to ensure that they receive the same level of comfort and care that is provided to all our patients.
          It is this dedication and our vast list of value-added services that make Wellness Pharmacy stand out from our competitors and that have earned us the trust and loyalty of our patients. -->

      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
module.exports = {
  computed: {
    //   for v-sheet purposes
    sheetHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '200px'
        case 'sm': return '300px'
        case 'md': return '300px'
        case 'lg': return '300px'
        case 'xl': return '350px'
      }
      return '300px'
    }
  }
}
</script>

<style scoped>

</style>
