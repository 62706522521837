<template>
  <v-stepper-content
  :step="number"
  >
    <v-text-field
    v-model="data.street"
    label="Street Address"
    required
    />
    <v-text-field
    v-model="data.state"
    label="State"
    required
    />
    <v-text-field
    v-model="data.zip"
    :rules="[rules.required, rules.zip]"
    label="Zip"
    required
    />

    <v-btn color="primary" @click="next">
      Continue
    </v-btn>
    <v-btn text @click="previous">
      Go Back
    </v-btn>
  </v-stepper-content>
</template>

<script>
export default {
  props: {
    next: Function,
    previous: Function,
    rules: Object,
    data: Object,
    number: String
  }
}
</script>
