<template>
  <v-card outlined min-width="100%" class="mb-5 pa-4">
    <div class="d-flex">
      <div class="d-flex flex-column justify-center">
        <v-card-title class="pt-0">{{ product.name }}</v-card-title>

        <v-card-subtitle>{{ product.price }} <br/> {{ product.location }}</v-card-subtitle>

        <v-btn color="success" class="ml-4" small :href="`mailto:${product.email}`">
            <v-icon small left>fa-envelope</v-icon>
            {{ product.email}}
        </v-btn>
        <v-btn color="primary" class="ml-4 mt-4" small>
            <v-icon small left>fa-phone-square</v-icon>
            {{ product.number}}
        </v-btn>
        <!-- <v-btn v-if="product.fax" color="primary" class="ml-4 mt-4" small>
            <v-icon small left>fa-fax</v-icon>
            {{ product.fax}}
        </v-btn> -->
      </div>
    </div>
  </v-card>
</template>

<script>
module.exports = {
  props: {
    product: Object,
    updateCart: Function,
    btnAction: String,
    i: Number
  }
}
</script>
