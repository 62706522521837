export default [
  // {
  //   image: 'flu_shot.jpg',
  //   image2: '',
  //   name: 'Flu Shots',
  //   price: '',
  //   location: 'Book your flu shots at one of our locations.',
  //   google: '',
  //   description: 'Book your COVID-19 vaccination at one of our locations.',
  //   visit_us: true
  // },
  {
    image: 'covid_vaccine.jpg',
    image2: '',
    name: 'COVID-19 Vaccine',
    price: '',
    location: 'Book your COVID-19 vaccination at one of our locations through the COVID Immunization website.',
    google: '',
    description: 'Book your COVID-19 vaccination at one of our locations.',
    gov: 'https://www.getvaccinated.gov.bc.ca/s/'
  },
  {
    image: 'home_monitoring.png',
    image2: '',
    name: 'Pharmacy Home Monitoring',
    price: 'Professional Licensed Practical Nurses (LPNs) and Care Aides will visit you daily to witness medication, measure your blood pressure, and perform other pharmacy services.',
    location: '',
    google: '',
    description: 'Will provide drugs to you',
    link: '/Referral Form Final Updated 20210916 - Fillable Version.pdf',
    contact_us: true
  },
  {
    image: 'dispensing.jpg',
    image2: '',
    name: 'Filling Prescriptions',
    price: 'Get your prescriptions filled at one of our pharmacies. We offer free contactless delivery and routine blister packs.',
    location: '',
    google: '',
    visit_us: true
  },
  {
    image: 'smart_blister.png',
    image2: '',
    name: 'Smart Blisters',
    price: 'Want to make sure your love one is taking their medication on time? Track it using Smart Blister.',
    location: '',
    google: '',
    contact_us: true
  },
  {
    image: 'compounding.png',
    image2: '',
    name: 'Compounding',
    price: 'We can formulate and customize medication that is not commerically available.',
    location: '',
    google: '',
    well3: true
  }
  // {
  //   image: 'pharmaconnect.jpg',
  //   image2: '',
  //   name: 'PharmaConnect',
  //   price: 'Refill your prescription online and track your medications.',
  //   location: '',
  //   google: '',
  //   contact_us: true
  // },
  // {
  //   image: 'virtual_consultation_desktop.png',
  //   image2: '',
  //   name: 'Virtual Consultation',
  //   price: 'Have questions about your medications? Talk to a pharmacist directly.',
  //   location: '',
  //   google: '',
  //   contact_us: true
  // },
  // {
  //   image: 'virtual_consultation_desktop.png',
  //   image2: '',
  //   name: 'Virtual Consultation',
  //   price: 'Talk to a pharmacist directly. ',
  //   location: '',
  //   google: ''
  // }
]
