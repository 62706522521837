export default [
  {
    image: 'Naz_2_front.jpeg',
    image2: 'Naz_2_in.jpeg',
    name: 'Pharmacy Home Monitoring',
    price: '#113 - 12578 72 Avenue',
    location: 'Surrey, British Columbia V3W 2M6',
    number: 'Tel: (604) 596-3241',
    fax: 'Fax: (604) 597-3267',
    email: 'hm@nazpharmacies.com',
    google: 'https://goo.gl/maps/6X733JzXvmVqqZLB9',
    book: 'https://b.telehippo.com/o/naz2',
    hours: ['Monday: 9am - 6pm', 'Tuesday: 9am - 6pm', 'Wednesday: 9am - 6pm', 'Thursday: 9am - 6pm', 'Friday: 9am - 6pm', 'Saturday: 7am - 4pm', 'Sunday: 7am - 4pm'],
    services: ['Pharmacy Home Monitoring', 'Filling Prescriptions', 'Medication Delivery', 'PharmaConnect', 'Virtual Consultation']
  },
  {
    image: 'Naz_1_front.jpeg',
    image2: 'Naz_1_in.jpeg',
    name: "Naz's Pharmacy No. 1",
    price: '#108 - 5990 Fraser St',
    location: 'Vancouver, British Columbia V5W 2Z7',
    number: 'Tel: (604) 323-1268',
    fax: 'Fax: (604) 323-1226',
    email: 'naz1@nazpharmacies.com',
    google: 'https://goo.gl/maps/GFXypwUJiAD1EkTs7',
    book: 'https://b.telehippo.com/o/naz2',
    hours: ['Monday: 9:30am - 6pm', 'Tuesday: 9:30am - 6pm', 'Wednesday: 9:30am - 6pm', 'Thursday: 9:30am - 6pm', 'Friday: 9:30am - 6pm', 'Saturday: 9:30am - 2pm', 'Sunday: CLOSED'],
    services: ['Pharmacy Home Monitoring', 'Filling Prescriptions', 'Medication Delivery', 'PharmaConnect', 'Virtual Consultation']
  },
  {
    image: 'Naz_2_front.jpeg',
    image2: 'Naz_2_in.jpeg',
    name: "Naz's Pharmacy No. 2",
    price: '#113 - 12578 72 Avenue',
    location: 'Surrey, British Columbia V3W 2M6',
    number: 'Tel: (604) 596-3241',
    fax: 'Fax: ',
    email: 'naz2@nazpharmacies.com',
    google: 'https://goo.gl/maps/6X733JzXvmVqqZLB9',
    book: 'https://b.telehippo.com/o/naz2',
    hours: ['Monday: 9am - 6pm', 'Tuesday: 9am - 6pm', 'Wednesday: 9am - 6pm', 'Thursday: 9am - 6pm', 'Friday: 9am - 6pm', 'Saturday: 7am - 4pm', 'Sunday: 7am - 4pm'],
    services: ['Pharmacy Home Monitoring', 'Filling Prescriptions', 'Medication Delivery', 'PharmaConnect', 'Virtual Consultation']
  },
  {
    image: 'Naz_3_front.jpeg',
    image2: 'Naz_3_in.jpeg',
    name: "Naz's Pharmacy No. 3",
    price: '#105 - 9385 120 Street',
    location: 'Delta, British Columbia V4C 0B5',
    number: 'Tel: (604) 585-1210',
    fax: 'Fax: ',
    email: 'naz3@nazpharmacies.com',
    google: 'https://goo.gl/maps/E4kPtZkVHNN3wnJcA',
    book: 'https://b.telehippo.com/o/naz3',
    hours: ['Monday: 9:30am - 5:30pm', 'Tuesday: 9:30am - 5:30pm', 'Wednesday: 9:30am - 5:30pm', 'Thursday: 9:30am - 5:30pm', 'Friday: 9:30am - 5:30pm', 'Saturday: 9am - 2pm', 'Sunday: CLOSED'],
    services: ['Smart Blister', 'Filling Prescriptions', 'Medication Delivery', 'PharmaConnect', 'Virtual Consultation']
  },
  {
    image: 'Well_3_front2.jpg',
    image2: 'Well_3_in.jpeg',
    name: 'Wellness Pharmacy #3',
    price: '#103 - 13737 96 Avenue',
    location: 'Surrey, British Columbia V3V 0C6',
    number: 'Tel: (604) 951-1002',
    fax: 'Fax: ',
    email: 'well3@nazpharmacies.com',
    google: 'https://goo.gl/maps/PfJbd62x4cq2gZMX7',
    book: 'https://b.telehippo.com/o/well3',
    hours: ['Monday: 9am - 6pm', 'Tuesday: 9am - 6pm', 'Wednesday: 9am - 6pm', 'Thursday: 9am - 6pm', 'Friday: 9am - 6pm', 'Saturday: 9am - 12pm', 'Sunday: CLOSED'],
    services: ['Compounding', 'Filling Prescriptions', 'Medication Delivery', 'PharmaConnect', 'Virtual Consultation']
  }
]
