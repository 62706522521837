<template>
  <v-hover v-slot:default="{ hover }">
    <v-card outlined>
      <v-img :src="require(`@/assets/images/${product.image}`)" height="200px">
        <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out v-card--reveal"
            style="height: 100%;"
          >
            <v-img :src="require(`@/assets/images/${product.image2}`)" height="200px" />
          </div>
        </v-expand-transition>
      </v-img>
      <v-card-title>{{product.name}}</v-card-title>
      <v-card-subtitle>
        {{ product.price }}
        <br />
        {{product.location}}
      </v-card-subtitle>
      <v-card-actions>
        <v-btn v-if="product.link" :href="`${product.link}`" download="Referral Form Final Updated 20210916 - Fillable Version.pdf" color="primary" dark>Referral Form</v-btn>
        <v-btn v-if="product.gov" :href="`${product.gov}`" color="primary" dark>Book</v-btn>
        <v-btn v-if="product.visit_us" href="/#locations" color="primary" dark>Our Locations</v-btn>
        <v-btn v-if="product.contact_us" href="/contact-us" color="primary" dark>Contact Us</v-btn>
        <v-btn v-if="product.well3" href="/store" color="primary" dark>Visit Wellness Pharmacy</v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
module.exports = {
  data () {
    return {
      dialog: false
    }
  },
  props: {
    product: Object,
    addToCart: Function
  }
}
</script>

<style scoped>
a:link {
  text-decoration: none;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
</style>
