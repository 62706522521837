import Vue from 'vue' // missing from browser-v

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Vue.use(Vuex) -- part of index.js for browser-v
// Vue.component ('app', App) -- part of index.js for browser-v

Vue.config.productionTip = false // missing from browser-v

new Vue({
  router, // router adds elements
  store,
  vuetify,
  render: h => h(App) // bjunc - https://github.com/vuejs-templates/webpack-simple/issues/29#issuecomment-312902539
}).$mount('#app')

// el:'app' is in browser-v, but use .mount(for manual)
// note: #app is in App.vue versus index.html (the html will import index.js and run, and inject App)
