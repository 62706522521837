<template>
<v-hover v-slot:default="{ hover }">
  <v-card outlined>
    <v-img :src="require(`@/assets/images/${product.image}`)" height="200px">
            <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out  v-card--reveal"
            style="height: 100%;"
          >
            <v-img :src="require(`@/assets/images/${product.image2}`)" height="200px"/>
          </div>
        </v-expand-transition>
    </v-img>
    <v-card-title>{{product.name}}</v-card-title>
    <v-card-subtitle>{{ product.price }} <br/> {{product.location}} <br/> {{product.number}}</v-card-subtitle>
    <v-card-actions>
      <v-btn  color="primary" outlined>
        <a :href="product.google" target="_blank"><v-icon small left>fa-location-arrow</v-icon>Directions</a>
      </v-btn>
      <!-- <v-btn  color="green" outlined>
        <a :href="product.book" target="_blank" style="color: green"><v-icon small left>fa-calendar</v-icon>Booking</a>
      </v-btn> -->
    </v-card-actions>
  </v-card>
  </v-hover>
</template>

<script>
module.exports = {
  props: {
    product: Object,
    addToCart: Function
  }
}
</script>

<style scoped>
a:link {
  text-decoration: none;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
</style>
