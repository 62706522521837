<template>
  <div>
    <v-app-bar>
      <v-toolbar-title>
        <v-avatar
                  width="auto"
                >
                  <img
                    alt="Avatar"
                    src="@/assets/images/logo-square.png"
                  >
                </v-avatar>
                </v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="hidden-sm-and-up">
          <v-btn @click.stop="drawer = !drawer">
              Menu
          </v-btn>
      </span>
      <v-toolbar-items class="hidden-xs-only">
        <!-- <v-btn href="/#locations" text>
          <v-icon small left>fa-calendar</v-icon>Book Flu Shot
        </v-btn> -->
        <v-btn to="/" text>
          <v-icon small left>fa-info-circle</v-icon>About
        </v-btn>
        <v-btn to="store" text>
          <v-icon small left>fa-map-marker</v-icon>Locations
        </v-btn>
        <v-btn to="contact-us" text>
          <v-icon small left>fa-envelope</v-icon>Contact Us
        </v-btn>
        <!-- <v-btn to="cart" text>
          <v-icon small left>fa-plus-square</v-icon>Services
        </v-btn>
        <v-btn to="delivery" text>
          <v-icon small left>fa-truck</v-icon>Delivery
        </v-btn> -->
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
    right
    >
    <v-list dense>
        <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.link"
        link
        >
        <v-list-item-icon>
            <v-icon small>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <!-- <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content> little diff from below -->
        {{ item.title }}
        </v-list-item>
    </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
module.exports = {
  data () {
    return {
      drawer: false,
      items: [
        { title: 'About', link: '/', icon: 'fa-home' },
        { title: 'Location', link: 'store', icon: 'fa-shopping-basket' },
        { title: 'Services', link: 'cart', icon: 'fa-plus-square' },
        { title: 'Delivery', link: 'delivery', icon: 'fa-shopping-cart' }
      ]
    }
  }
}
</script>

<style scoped>
/* v-toolbar-title {
  margin-top: 2em
} */
</style>
