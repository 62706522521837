import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Store from '../views/Store'
import Cart from '../views/Cart'
import Checkout from '../views/Checkout'
import ThankYou from '../views/ThankYou'
import Delivery from '../views/Delivery'
import ContactUs from '../views/ContactUs'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'About', component: Home },
  { path: '/store', name: 'store', component: Store },
  { path: '/contact-us', name: 'contactus', component: ContactUs },
  { path: '/cart', name: 'cart', component: Cart },
  { path: '/checkout', name: 'checkout', component: Checkout },
  { path: '/thank-you', name: 'thankyou', component: ThankYou },
  { path: '/delivery', name: 'delivery', component: Delivery }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
