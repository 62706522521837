<template>
<v-container mb-12 mt-8>
  <v-divider/>
  <v-row>
    <v-col cols="10" offset="1" mt-10>
      <h2>Services</h2>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="10" offset-sm="1" lg="10" offset-md="1">
      <v-row >
        <v-col
        sm="6"
        md="4"
        v-for="(service) in $store.state.services.slice(0,6)"
        :key="service.name"
        >
        <Services :product="service" :addToCart="addToCart"></Services>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-divider/>
  <v-row>
    <v-col sm="10" offset-sm="1" lg="10" offset-md="1">
      <h2 id="locations">Locations <v-btn to="store" color="primary" small text>View All</v-btn> </h2>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="10" offset-sm="1" lg="10" offset-md="1">
      <v-row>
        <v-col
        sm="6"
        md="4"
        v-for="(product) in $store.state.products.slice(1,5)"
        :key="product.name"
        >
        <VerticalProduct :product="product" :addToCart="addToCart"></VerticalProduct>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import VerticalProduct from '../cards/VerticalProduct'
import Services from '../cards/Services'

export default {
  components: {
    VerticalProduct,
    Services
  },
  data () {
    return {}
  },
  methods: {
    addToCart (index, quantity = 1) {
      this.$store.commit('addItemToCart', { itemId: index, quantity })
      this.$store.commit('updateSnackbar', { show: true })
    }
  }
}
</script>
