<template>
  <!-- <div class="home"> -->
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div>
    <Header />
    <PopularProducts />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '../components/home/Header'
import PopularProducts from '../components/home/PopularProducts'

export default {
  name: 'Home',
  components: {
    // HelloWorld
    Header,
    PopularProducts
  }
}
</script>
