<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(product) in $store.state.products"
        :key="product.name"
      >
        <HorizontalProduct :product="product" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HorizontalProduct from '../components/cards/HorizontalProduct'

export default {
  components: {
    HorizontalProduct
  }
}
</script>
